//Credenciales Jose Daniel
// export const GoogleKeyAPI = "AIzaSyC9tofvYCSdSYrSpJtlosSQHU3KAm7abfg";

//Credenciales Neohyundai - Augusto Arias
export const GoogleKeyAPI = "AIzaSyBKmCZbOo7zIJZI0gVycqKIEJxYRO-Iem0";

// Servidor Local
/*export const Server = "http://192.168.210.68:8000"*/
//export const Server = "http://127.0.0.1:8000" 
export const Server = "https://kerner.hyundai.com.ec"; 
export const GoogleAnalyticsID = "UA-202221412-1";
export const FacebookPixelID = '3465283043696099';
export const GoogleTagManagerID = { gtmId: "GTM-MKX77M7"};

// SGC
export const tipoInteresado = {
    PáginaWeb: 11,
    Perzonaliza: 6,
    TestDrive: 55
}