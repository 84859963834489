import React, { Suspense, lazy } from "react";
import { Route, Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import "./components/exocotizador/css/exonerado.css";
import LoadingAnimation from '../src/components/loading/LoadingAnimation';
import Analytics from "./services/Analytics";
const Index = lazy(() => import("./components/pages/Index"));
const ScrollToTop = lazy(() => import("./components/scroll/ScrollToTop"));
const Grandi10Hb = lazy(() => import("./components/pages/Grandi10Hb"));
const NuevoCreta = lazy(() => import("./components/pages/NuevoCreta"));
const NuevoSonata = lazy(() => import("./components/pages/NuevoSonata"));
const NuevoTucson = lazy(() => import("./components/pages/NuevoTucson"));
const Vehiculos = lazy(() => import("./components/pages/Vehiculos"));
const Noticias = lazy(() => import("./components/pages/Noticias"));
const Articulo = lazy(() => import("./components/pages/Articulo"));
const Taller = lazy(() => import("./components/pages/Taller"));
const Ventas = lazy(() => import("./components/pages/Ventas"));
const Disclaimer = lazy(() => import("./components/pages/Disclaimer"));
const Cotizador = lazy(() => import("./components/pages/Cotizador"));
const TestDrive = lazy(() => import("./components/pages/TestDrive"));
const ConfiguraHyundai = lazy(() => import("./components/pages/ConfiguraHyundai"));
const LiquidacionRepuestos = lazy(() => import("./components/pages/LiquidacionRepuestos"));
const EcommerceRepuestos = lazy(() => import("./components/pages/EcommerceRepuestos"));
const EcommerceCheckout = lazy(() => import("./components/pages/EcommerceCheckout"))
const Condiciones = lazy(() => import("./components/pages/Condiciones"));
const Campanias = lazy(() => import("./components/pages/Campanias"));
const Mantenimiento = lazy(() => import("./components/pages/Mantenimiento"));
const Manual = lazy(() => import("./components/pages/Manual"));
const Repuestos = lazy(() => import("./components/pages/Repuestos"));
const DerechoArco = lazy(() => import("./components/pages/DerechoArco"));
const Innovacion = lazy(() => import("./components/pages/Innovacion"));
const Vision = lazy(() => import("./components/pages/Vision"));
const Citas = lazy(() => import("./components/pages/Citas"));
const Terminos = lazy(() => import("./components/pages/Terminos"));
const PoliticaPrivacidad = lazy(() => import("./components/pages/PoliticaPrivacidad"));
const PoliticaCalidad = lazy(() => import("./components/pages/PoliticaCalidad"));
const MisionVision = lazy(() => import("./components/pages/MisionVision"));
const Gracias = lazy(() => import("./components/pages/Gracias"));
const GraciasRepuesto = lazy(() => import("./components/pages/GraciasRepuesto"));
const NuevoSantaFe = lazy(() => import("./components/pages/NuevoSantaFe"));
const SeguroHyundai = lazy(() => import("./components/pages/Seguros"));
const Kona = lazy(() => import("./components/pages/Kona"));
const NuevoAccent2024 = lazy(() => import("./components/pages/NuevoAccent2024"));
const Grandi10Sedan2023 = lazy(() => import("./components/pages/Grandi10Sedan2023"));
const MantenimientosPrepagados = lazy(() => import("./components/pages/MantenimientosPrepagados"));
const Staria = lazy(() => import("./components/pages/Staria"));
const Ioniq5 = lazy(() => import("./components/pages/Ioniq5"));
const RescueSheets = lazy(() => import("./components/pages/Rescue Sheets"));
const MercadoValores = lazy(() => import("./components/pages/MercadoValores"));
const Palisade = lazy(() => import("./components/pages/Palisade"));
const Exonerados = lazy(() => import("./components/pages/Exonerados"));
const Suenios = lazy(() => import("./components/pages/Suenios"));
const browserHistory = createBrowserHistory();
function App() {
    return (
        <Suspense fallback={<LoadingAnimation />}>
            <div className="App">
                <Router
                    history={browserHistory}
                >
                    <Analytics />
                    <ScrollToTop />
                    <Switch>
                        <Route exact path="/" component={Index} />
                        {/*Autos*/}
                        <Route path="/grandi10hb" component={Grandi10Hb} />
                        <Route path="/grandi10sedan" component={Grandi10Sedan2023} />
                        <Route path="/nuevo-accent" component={NuevoAccent2024} />
                        <Route path="/nuevo-sonata" component={NuevoSonata} />
                        {/*Suvs*/}
                        <Route path="/nuevo-creta" component={NuevoCreta} />
                        <Route path="/nuevo-tucson" component={NuevoTucson} />
                        <Route path="/nuevosantafe" component={NuevoSantaFe} />
                        <Route path="/ioniq5" component={Ioniq5} />
                        <Route path="/kona" component={Kona} />
                        <Route path="/palisade" component={Palisade} ></Route>
                        {/*Vans*/}
                        <Route path="/staria" component={Staria} ></Route>

                        <Route path="/vehiculos/:procedencia" component={Vehiculos} />
                        <Route path="/vehiculos" component={Vehiculos} />
                        <Route path="/noticias" component={Noticias} />
                        <Route path="/articulo/:categoria" component={Articulo} />
                        <Route path="/talleres" component={Taller} />
                        <Route path="/ventas" component={Ventas} />

                        {/* <Route path="/promesa-cliente" component={PromesaCliente} /> 
                        <Route path="/programas-servicio" component={ProgramaServicio} />
                        <Route path="/entrenamiento" component={Entrenamiento} />
                        <Route path="/eventos" component={Eventos} /> 
                        <Route path="/campeonato-de-constructores" component={Rally} />
                        <Route path="/quedate-en-casa" component={Casa} />
                        <Route path="/construyendo-suenos" component={Suenios} />
                        <Route path="/hyundai-entrega-pantallas-de-separacion" component={Pantallas} />
                        */}
                        <Route path="/construyendo-suenos" component={Suenios} />
                        <Route path="/condiciones" component={Condiciones} />
                        <Route path="/campanas" component={Campanias} />
                        <Route path="/mantenimiento" component={Mantenimiento} />
                        <Route path="/manual-del-propietario" component={Manual} />
                        <Route path="/seguro-hyundai" component={SeguroHyundai} />
                        <Route path="/repuestos" component={Repuestos} />
                        <Route path="/gracias-repuesto" component={GraciasRepuesto} />
                        <Route path="/derecho-arco" component={DerechoArco} />
                        <Route path="/citas-taller" component={Citas} />
                        <Route path="/cotizador/gracias" component={Gracias} />
                        <Route path="/cotizador/:modelo" component={Cotizador} />
                        <Route path="/test-drive/:modelo" component={TestDrive} />
                        <Route path="/nuestra-vision" component={Vision} />
                        <Route path="/innovacion" component={Innovacion} />
                        <Route path="/exonerados/:modelo" component={Exonerados}/>
                        
                        <Route path="/construccion" component={Disclaimer} />
                        <Route path="/terminos-y-condiciones" component={Terminos} />
                        <Route path="/politica-privacidad" component={PoliticaPrivacidad} />
                        <Route path="/politica-calidad" component={PoliticaCalidad} />
                        <Route path="/mision-vision" component={MisionVision} />
                        <Route path="/gracias" component={Gracias} />
                        <Route path="/prepagados" component={MantenimientosPrepagados} />
                        <Route path="/mercado-valores" component={MercadoValores} />
                        <Route path="/postventa/rescue-sheets" component={RescueSheets} />
                        <Route path="/configura-tu-hyundai" component={ConfiguraHyundai} />
                        <Route path="/liquidacion-repuestos" component={LiquidacionRepuestos} />
                        <Route path="/ecommerce-repuestos" component={EcommerceRepuestos} />
                        <Route path="/pago" component={EcommerceCheckout} />
                        <Route path="*" component={Index} />
                    </Switch>
                </Router>
            </div>
        </Suspense>
    );
}
export default App;